import React from 'react';

export default function NotFound(){

    return (
        <div>
            <div>
            <h1 className="h1_404"> 404 Erreur la page est introuvable ! </h1>
            </div>           
        </div>
    );
};