import React from 'react'

export default function Top2() {

    return (
        <section id="sec-S">
            <div className="marbre">
                <div className="container">
                    <h1>Mickaël Boï</h1>
                    <p>DevOps <br/> Debian / Docker / Symfony / React etc..</p>
                </div>
            </div>
        </section>
    )
}